import React, { useState, useEffect } from "react"
import { useUser, useUserDispatch } from "../contexts/user";
import moment from "moment";
import { createAssignment } from "../api/assignments";
import { useNavigate, useParams } from 'react-router-dom'
import { getOrgMembers } from "../api/organizations";
import { getTeam } from "../api/teams";

export default function AssignmentForm(args) {
  const user = useUser();
  const navigate = useNavigate();
  const { team_id } = useParams();
  const { id, organization } = user;
  const dispatch = useUserDispatch();
  const [loading, setLoading] = useState(false)
  const [updating] = useState(false)
  const [assignment, setAssignment] = useState({
    user: null,
    team: team_id,
    org: user.organization,
    start_date: moment().format("MM/DD/YYYY"),
    end_date: moment().add(7, 'days').format("MM/DD/YYYY"),
    on_call_confirmation_required: true,
    order: 'primary'
  });

  const [team, setTeam] = useState(null)


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const fetchTeam = async () => {
    setLoading(true)
    const { token, id } = user;
    const response = await getTeam({ token, user_id: id, org_id: organization, team_id })
    if (!response) return;
    setTeam({ ...response.data })
    setLoading(false)
  }

  useEffect(() => {
    fetchTeam()
  }, [])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setAssignment({ ...assignment, [name]: val });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token, id } = user;

    // if (updating) { // this is tech debt
    //   handleUpdateNotification({ notification_id: _id, name, recipients, accounts, user_id: id, token, dispatch })
    // } else {
    const result = await createAssignment({ assignment, token, user_id: id, org_id: organization, team_id })
    console.log({ result })
    navigate(-1)
    // }
  }


  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 mb-8">
          <div className="space-y-8">
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">New Assignment</h3>
                <p className="mt-1 text-sm text-gray-500">Select a user, start date and end date</p>
              </div>
            </div>
            <div className="mt-6">
              <div>
                <h2 className="text-sm font-medium text-gray-500">Select A User</h2>
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {team?.members?.length && team?.members.map((member) => (
                    <li key={member.email}
                      onClick={() => setAssignment({ ...assignment, user: member._id })}
                      className="col-span-1 flex rounded-md shadow-sm">
                      <div
                        className={classNames(
                          `flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white ${assignment.user === member._id ? 'bg-emerald-500' : 'bg-blue-500'}`
                        )}
                      >
                        {member.email[0]}
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:border-blue-500">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <a href='#' className="font-medium text-gray-900 hover:text-gray-600">
                            {member.name}
                          </a>
                          {/* <p className="text-gray-500">{project.members} Members</p> */}
                        </div>
                        {/* <div className="flex-shrink-0 pr-2">
                        <button
                          type="button"
                          className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-6">
              <fieldset className="mt-6">
                <label htmlFor="recipient-name" className="block text-sm font-medium text-gray-700">
                  Start Date
                </label>
                <div className="mt-1">
                  <input
                    id="start_date"
                    name="start_date"
                    onChange={handleChange}
                    value={assignment.start_date}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  />
                </div>
              </fieldset>
            </div>
            <div className="mt-6">
              <fieldset className="mt-6">
                <label htmlFor="recipient-name" className="block text-sm font-medium text-gray-700">
                  End Date
                </label>
                <div className="mt-1">
                  <input
                    id="end_date"
                    name="end_date"
                    onChange={handleChange}
                    value={assignment.end_date}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  />
                </div>
              </fieldset>
            </div>

            <div className="mt-6">
              <fieldset className="mt-6">
                <label htmlFor="recipient-name" className="block text-sm font-medium text-gray-700">
                  Shift Order
                </label>
                <div className="mt-1">
                  <select
                    id="order"
                    name="order"
                    onChange={handleChange}
                    value={assignment.order}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  >
                    <option>primary</option>
                    <option>secondary</option>
                    <option>tertiary</option>
                  </select>
                </div>
              </fieldset>
            </div>

            <div className="mt-6">
              <fieldset className="mt-6">
                <div className="flex items-center">
                  <input
                    id="on_call_confirmation_required"
                    name="on_call_confirmation_required"
                    onChange={handleChange}
                    checked={assignment.on_call_confirmation_required}
                    type="checkbox"
                    className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded"
                  />
                  <label htmlFor="on_call_confirmation_required" className="ml-2 block text-sm font-medium text-gray-700">
                    On-Call Shift Start Confirmation Required
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                {updating ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
