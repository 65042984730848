import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getOrgTeams } from "../api/organizations";

// todo:
// add loading screen
// make card header better

export default function TeamList(args) {
  const user = useUser();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([])
  const user_is_admin = user.organization_role != 'member';
  const [loading, setLoading] = useState(false)

  const fetchTeams = async () => {
    setLoading(true)
    const response = await getOrgTeams({ token: user.token, user_id: user.id, org_id: user.organization })
    if (!response) return;
    setTeams([...response.data])
    setLoading(false)
  }

  useEffect(() => {
    fetchTeams()
    console.log({ teams })
  }, [setTeams])


  return (
    <>
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {user_is_admin && <button
              type="button"
              onClick={() => navigate(`/org/${user.organization}/teams/new`)}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            >
              Create Team
            </button>}
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Members
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {teams?.map((team, i) => (
                      <tr key={i} >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-emerald-600 sm:pl-6 hover:text-emerald-900 ">
                          <Link to={`/org/${user.organization}/teams/${team._id}`}>
                            {team?.name}
                          </Link>
                        </td>

                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {team?.members?.length}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
} 