import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import moment from "moment-timezone";
const TIMEZONE = process.env.REACT_APP_TIMEZONE;
import Pagination from "../components/Pagination";
import { getOrgAssignments } from "../api/assignments";
import { getOrgNotifications } from "../api/notifications";
import { getOrgIssues } from "../api/issues";
import { getOrgTeams } from "../api/organizations";
import { getOrgUsers } from "../api/users";

// todo:
// add loading screen
// update component state with response on creation of assignments, etc - this might already be working
// update all routes with necessary info, get user_id off token
// take add assignment button off org assignments
// combine tables where possible
// query data when view is opened

// set up assignments, test issue flow
// add padding to icons on tables for mobile view 
// delete assignment
// fix UI for stats,
// get UI for orgs
// load teams in queries
// gear icon for delete member
// delete team 


export default function Org(args) {
  const user = useUser();
  const navigate = useNavigate();
  const { org_id, team_id } = useParams();
  const user_is_admin = user.organization_role != 'member';
  const [loading, setLoading] = useState(false)
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])

  const [issues, setIssues] = useState([])
  const [issuePageNumber, setIssuePageNumber] = useState(0)
  const [issueCount, setIssueCount] = useState(10)

  const [notifications, setNotifications] = useState([])
  const [notificationPageNumber, setNotificationPageNumber] = useState(0)
  const [notificationCount, setNotificationCount] = useState(10)

  const [assignments, setAssignments] = useState([])
  const [currentAssignments, setCurrentAssignments] = useState([])
  const [pastAssignments, setPastAssignments] = useState([])
  const [futureAssignments, setFutureAssignments] = useState([])


  const isCurrentAssignment = (assignment) => {
    return moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())
  }

  const deleteAssignmentClicked = async (assignment_id) => {
    // const response = await deleteAssignment({ token: user.token, user_id: user.id, assignment_id })
    // if (!response) return;
    // fetchAssignments()
  }

  const fetchOrgIssues = async () => {
    setLoading(true)
    const response = await getOrgIssues({ token: user.token, user_id: user.id, org_id, team_id, page_number: issuePageNumber, count: issueCount })
    if (!response) return;
    setIssues([...response.data])
    setLoading(false)
  }
  const fetchOrgUsers = async () => {
    setLoading(true)
    const response = await getOrgUsers({ token: user.token, user_id: user.id, org_id, team_id, page_number: issuePageNumber, count: issueCount })
    if (!response) return;
    setUsers([...response.data])
    setLoading(false)
  }

  const fetchOrgAssignments = async () => {
    setLoading(true)
    const response = await getOrgAssignments({ token: user.token, org_id, page_number: issuePageNumber, count: issueCount })
    if (!response) return;

    setPastAssignments([...response.data].filter(assignment => moment(assignment.end_date).isBefore(moment())))
    setCurrentAssignments([...response.data].filter(assignment => moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())))
    setFutureAssignments([...response.data].filter(assignment => moment(assignment.start_date).isAfter(moment())))
    setAssignments([...response.data])
    setLoading(false)
  }
  const fetchOrgNotifications = async () => {
    setLoading(true)
    const response = await getOrgNotifications({ token: user.token, user_id: user.id, org_id, team_id, page_number: notificationPageNumber, count: notificationCount })
    if (!response) return;
    setNotifications([...response.data])
    setLoading(false)
  }

  const fetchOrgTeams = async () => {
    setLoading(true)
    const response = await getOrgTeams({ token: user.token, user_id: user.id, org_id, team_id })
    if (!response) return;
    setTeams([...response.data])
    setLoading(false)
  }
  useEffect(() => {
    fetchOrgIssues()
  }, [issuePageNumber])

  useEffect(() => {
    fetchOrgNotifications()
  }, [notificationPageNumber])


  useEffect(() => {
    fetchOrgTeams()
    fetchOrgUsers()
    fetchOrgIssues()
    fetchOrgNotifications()
    fetchOrgAssignments()
  }, [])


  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="px-4 pt-8 sm:px-6 lg:px-8">

          <div className="mt-8 flex flex-col">
            <h1 className="text-base font-semibold text-xl leading-6 text-gray-900">Org</h1>

            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <AssignmentTable
                  assignment_type={'Current Assignment'}
                  assignments={currentAssignments}
                  user_is_admin={user_is_admin}
                  isCurrentAssignment={isCurrentAssignment}
                  deleteAssignmentClicked={deleteAssignmentClicked}
                />
                <AssignmentTable
                  assignment_type={'Future Assignments'}
                  assignments={futureAssignments}
                  user_is_admin={user_is_admin}
                  isCurrentAssignment={isCurrentAssignment}
                  deleteAssignmentClicked={deleteAssignmentClicked}
                  showCreateButton={true}
                  createButtonLink={`/org/${org_id}/teams/${team_id}/assignments/new`}
                  navigate={navigate}
                />
                <IssuesTable
                  issues={issues}
                  count={issueCount}
                  setCount={setIssueCount}
                  pageNumber={issuePageNumber}
                  setPageNumber={setIssuePageNumber}
                />
                <NotificationsTable
                  notifications={notifications}
                  count={notificationCount}
                  setCount={setNotificationCount}
                  pageNumber={notificationPageNumber}
                  setPageNumber={setNotificationPageNumber}
                />
                <TeamsTable
                  teams={teams || []}
                  user_is_admin={user_is_admin}
                  showCreateButton={true}
                  createButtonLink={`/org/${user.organization}/teams/new`}
                  navigate={navigate}
                />
                <MembersTable
                  members={users || []}
                  user_is_admin={user_is_admin}
                  showCreateButton={true}
                  createButtonLink={`/org/${user.organization}/members/new`}
                  navigate={navigate}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {user_is_admin && <Link to={`/org/${org_id}/admin`}>Admin Panel</Link>}

        </div>
      </div>
    </>
  )
}

function MembersTable({ members, user_is_admin, showCreateButton = true, createButtonLink = '', navigate }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between center">
                <div className="flex align-center">
                  <div
                    className="pr-4 hover:text-emerald-600"
                    onClick={() => setShowTable(!showTable)}
                  >
                    {!showTable
                      ? <PlusCircleIcon className="h-6 w-6" />
                      : <MinusCircleIcon className="h-6 w-6" />
                    }
                  </div>
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Members</h1>
                </div>
                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {!showTable
              ? <></>
              : <div >
                {loading
                  ? <>Loading</>
                  :
                  <div className="overflow-hidden  ring-black ring-opacity-5 ">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Team
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Email
                          </th>
                          {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Role
                          </th>}
                          {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {members?.map((member, i) => (
                          <tr key={i} >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-emerald-600 sm:pl-6 hover:text-emerald-900 ">
                              <Link to={`/profile/${member._id}`}>
                                {member?.name}
                              </Link>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 hover:text-emerald-900 ">
                              {!!member?.team
                                ? <Link to={`/teams/${member?.team?._id}`}>
                                  {member?.team?.name}
                                </Link>
                                : <p>N/A</p>
                              }
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {member?.email}
                            </td>
                            {user_is_admin && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{member.organization_role}</td>}
                            {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                }
              </div>
            }

          </div>
        </div>
      </div >
    </>
  )

}

// assignments - add assignment
// issues
// notifications
// members - add member, - edit member, - remove member from org/team

function AssignmentTable({ assignment_type, assignments, user_is_admin, isCurrentAssignment, deleteAssignmentClicked, navigate, showCreateButton = false, createButtonLink = null }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between">
                <div className="flex">
                  <div
                    className="pr-4 hover:text-emerald-600"
                    onClick={() => setShowTable(!showTable)}
                  >
                    {!showTable
                      ? <PlusCircleIcon className="h-6 w-6" />
                      : <MinusCircleIcon className="h-6 w-6" />
                    }
                  </div>
                  <h1 className="text-base font-semibold leading-6 text-gray-900">{assignment_type}</h1>
                </div>
                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {!showTable
              ? <></>
              : <div >
                {loading
                  ? <>Loading</>
                  :
                  <div className="overflow-hidden ">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Team
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Start
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            End
                          </th>
                          {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Delete
                          </th>}
                          {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {assignments?.map((assignment, i) => (
                          <tr key={i} className={`${isCurrentAssignment(assignment) && 'bg-blue-200'}`}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                              <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${assignment.user.id}`}>
                                {assignment?.user?.name}
                              </Link>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 hover:text-emerald-900 ">
                              {!!assignment?.team
                                ? <Link to={`/teams/${assignment?.team?._id}`}>
                                  {assignment?.team?.name}
                                </Link>
                                : <p>N/A</p>
                              }
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {moment(assignment?.start_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                              {/* <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span> */}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {moment(assignment?.end_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                            </td>
                            {user_is_admin && <td
                              className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-red-500 hover:text-red-900"
                              onClick={() => deleteAssignmentClicked(assignment.id)}
                            >
                              Delete
                            </td>}
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                            {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                }
              </div>
            }

          </div>
        </div>
      </div >
    </>
  )

}

function IssuesTable({ issues, count, setCount, pageNumber, setPageNumber }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <div className="mt-8 flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border border-gray-200 rounded-md">
      <div className="p-4 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="my-4">
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="pr-4 hover:text-emerald-600"
                  onClick={() => setShowTable(!showTable)}
                >
                  {!showTable
                    ? <PlusCircleIcon className="h-6 w-6" />
                    : <MinusCircleIcon className="h-6 w-6" />
                  }
                </div>
                <h1 className="text-base font-semibold leading-6 text-gray-900">Issues</h1>
              </div>
            </div>
          </div>
          {!showTable
            ? <></>
            : <div >
              {loading
                ? <>Loading</>
                :
                <div className="overflow-hidden ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Issue ID
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Source
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Team
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Date Created
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Ack'd At
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Ack'd By
                        </th>
                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {issues?.map((issue, i) => (
                        <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <a className="text-emerald-600 hover:text-emerald-900" href={issue.issue_url}>
                              {(issue?._id).slice(-4)}
                            </a>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {issue?.source}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 hover:text-emerald-900 ">
                            {!!issue?.team
                              ? <Link to={`/teams/${issue?.team?._id}`}>
                                {issue?.team?.name}
                              </Link>
                              : <p>N/A</p>
                            }
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment.utc(issue?.date_created).local().format('MM/DD/YY hh:mm:ss A')}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {issue?.acknowledged ? moment.utc(issue?.acknowledged_at).local().format('MM/DD/YY hh:mm:ss A') : 'unacknowledged'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {(!!issue?.acknowledged_by)
                              ? <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${issue?.acknowledged_by._id}`}>{issue?.acknowledged_by?.name}</Link>
                              : 'N/A'
                            }
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end mr-2 mt-4">
                    <Pagination
                      count={count}
                      setCount={setCount}
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                    />
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

function TeamsTable({ teams, user_is_admin, showCreateButton = true, createButtonLink = '', navigate }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between center">
                <div className="flex align-center">
                  <div
                    className="pr-4 hover:text-emerald-600"
                    onClick={() => setShowTable(!showTable)}
                  >
                    {!showTable
                      ? <PlusCircleIcon className="h-6 w-6" />
                      : <MinusCircleIcon className="h-6 w-6" />
                    }
                  </div>
                  <h1 className="text-base font-semibold leading-6 text-gray-900">Teams</h1>
                </div>
                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {!showTable
              ? <></>
              : <div >
                {loading
                  ? <>Loading</>
                  :
                  <div className="overflow-hidden  ring-black ring-opacity-5 ">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Name
                          </th>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Email
                          </th>
                          {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Role
                          </th>}
                          {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {teams?.map((team, i) => (
                          <tr key={i} >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-emerald-600 sm:pl-6 hover:text-emerald-900 ">
                              <Link to={`/teams/${team._id}`}>
                                {team?.name}
                              </Link>
                            </td>

                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {team?.name}
                              {/* <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span> */}
                            </td>
                            {user_is_admin && <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{team.name}</td>}
                            {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                            {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                }
              </div>
            }

          </div>
        </div>
      </div >
    </>
  )

}

function NotificationsTable({ notifications, count, setCount, pageNumber, setPageNumber }) {
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <div className="mt-8 flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border border-gray-200 rounded-md">
      <div className="p-4 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="my-4">
            <div className="flex justify-between">
              <div className="flex">
                <div
                  className="pr-4 hover:text-emerald-600"
                  onClick={() => setShowTable(!showTable)}
                >
                  {!showTable
                    ? <PlusCircleIcon className="h-6 w-6" />
                    : <MinusCircleIcon className="h-6 w-6" />
                  }
                </div>
                <h1 className="text-base font-semibold leading-6 text-gray-900">Notifications</h1>
              </div>
            </div>
          </div>
          {!showTable
            ? <></>
            : <div >
              {loading
                ? <>Loading</>
                :
                <div className="overflow-hidden ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Issue ID
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Team
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Cell
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Date Sent
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Time Sent
                        </th>
                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {notifications?.map((notification, i) => (
                        <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                            {(!!notification?.issue)
                              ? <a className="text-emerald-600 hover:text-emerald-900" href={notification.issue.issue_url}>
                                {(notification?.issue?._id).slice(-4)}
                              </a>
                              : <>{(notification?.issue_id).slice(-4)}</>
                            }
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-emerald-600 sm:pl-6 hover:text-emerald-900 ">
                            <Link to={`/profile/${notification?.user?._id}`}>
                              {notification?.user?.name}
                            </Link>
                          </td>

                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-black sm:pl-6 hover:text-emerald-900 ">
                            {!!notification?.team
                              ? <Link to={`/teams/${notification?.team?._id}`}>
                                {notification?.team?.name}
                              </Link>
                              : <p>N/A</p>
                            }
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                            {notification?.user?.cell}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                            {moment(notification?.date_created).format('MM/DD/YYYY')}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                            {moment.utc(notification?.date_created).local().format('hh:mm:ss A')}
                          </td>
                          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                          {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex justify-end mr-2 mt-4">
                    <Pagination
                      count={count}
                      setCount={setCount}
                      setPageNumber={setPageNumber}
                      pageNumber={pageNumber}
                    />
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}
