import React, { useEffect, useState, Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser, useUserDispatch } from '../contexts/user';
import { getOrgTeams } from '../api/organizations';
import { getStatus } from '../api/status';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const initNavigation = (user, teams = []) => {
  const teamNav = teams.map(team => ({
    name: team.name,
    href: `/teams/${team._id}`,
  }));

  return [
    { name: 'Home', href: `/home/${user.id}` },
    ...teamNav,
    { name: 'Org', href: `/org/${user?.organization}` },
  ];
};

const fetchStatus = async (setStatuses, setStatusColor) => {
  try {
    const response = await getStatus();
    if (response) {
      setStatuses([...response.data]);
      const latestStatus = getLatestStatus([...response.data]);
      setStatusColor(latestStatus.status == 'healthy' ? 'bg-green-500' : 'bg-red-500');

    }
  } catch (error) {
    console.error('Failed to fetch status', error);
  }
};

const getLatestStatus = (statuses) => {
  if (statuses.length === 0) {
    return null;
  }
  return statuses.reduce((latest, status) => {
    return new Date(status.date_created) > new Date(latest.date_created) ? status : latest;
  }, statuses[0]);
};

const fetchTeams = async (user, setTeams, setNav) => {
  try {
    const response = await getOrgTeams({
      token: user.token,
      user_id: user.id,
      org_id: user.organization,
    });
    if (response) {
      setTeams(response.data);
      setNav(initNavigation(user, response.data));
    }
  } catch (error) {
    console.error('Failed to fetch teams', error);
  }
};


export default function Navbar() {
  const user = useUser();
  const dispatch = useUserDispatch();
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [statuses, setStatuses] = useState('bg-gray-500');
  const [statusColor, setStatusColor] = useState();
  const [showStatusTooltip, setShowStatusTooltip] = useState(false);
  const [nav, setNav] = useState(() => initNavigation(user));

  useEffect(() => {
    fetchStatus(setStatuses, setStatusColor)
    !!user && fetchTeams(user, setTeams, setNav);
  }, [user]);

  const signout = () => {
    dispatch({ type: 'deleted', user: null });
    navigate('/');
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          {showStatusTooltip && (
            <div className=" fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg  w-2/4 max-w-2xl justify-center items-center">
                {statuses.length ? (
                  <table className="min-w-full ">
                    <thead>
                      <tr>
                        <th className="px-2 py-1 text-left text-sm font-medium text-gray-900">Timestamp</th>
                        <th className="px-2 py-1 text-left text-sm font-medium text-gray-900">Status</th>
                        <th className="px-2 py-1 text-left text-sm font-medium text-gray-900">Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statuses.map((status, i) => {
                        return (
                          <tr key={i}>
                            <td className="px-2 py-1 text-sm text-gray-700">{moment(status.date_created).format("MM/DD hh:mm A")}</td>

                            <td className="px-2 py-1 text-sm text-gray-700">{status.status}</td>
                            <td className="px-2 py-1 text-sm text-gray-700">{status.message}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-sm text-gray-700">No status available</p>
                )}
                <div className='flex justify-end'>
                  <button
                    className="mt-4 bg-emerald-600 text-white px-2 py-1 rounded"
                    onClick={() => setShowStatusTooltip(false)}
                  >
                    Close
                  </button>
                </div>
              </div>

            </div>
          )}
          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <BellIcon className="h-6 pr-2 rounded-full text-white" aria-hidden="true" />
                  <h1 className='text-white font-bold '>Bellburd</h1>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {nav.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'px-3 py-2 rounded-md text-sm font-medium'
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <div className="hidden md:flex md:flex-shrink-0 md:items-center">
                  <span
                    className={`inline-block h-2 w-2 rounded-full ${statusColor}`}
                    onMouseEnter={() => setShowStatusTooltip(true)}
                  />
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <Menu.Button className="flex text-sm rounded-full bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open user menu</span>
                      <UserIcon className="h-6 w-6 rounded-full text-white" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={signout}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => navigate('/settings')}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}
                            >
                              Reset password
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {nav.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={NavLink}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block px-3 py-2 rounded-md text-base font-medium'
                    )
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <span className={`inline-block h-2 w-2 rounded-full ml-3 ${statusColor}`} />

            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
