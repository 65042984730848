import React, { useEffect, useState } from "react"
import { useUser, useUserDispatch } from "../contexts/user";
import { createUser, getOrgTeams } from '../api/organizations'
import { useNavigate, useParams } from 'react-router-dom';

export default function MemberForm({ setShowForm }) {
  const user = useUser();
  const { token, id, organization } = user;
  const { org_id } = useParams()
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const [updating] = useState(false)
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState([])

  const [memberData, setMemberData] = useState({
    cell: null,
    name: null,
    email: null,
    title: 'L1',
    role: 'member',
    stack: 'front-end',
    team: null
  });

  const fetchTeams = async () => {
    setLoading(true)
    const response = await getOrgTeams({ token, user_id: id, org_id: organization })
    if (!response) return;
    setTeams([...response.data])
    setLoading(false)
  }


  useEffect(() => {
    fetchTeams()
  }, [])



  const handleChange = (e) => {
    if (e.target.value == 'None') {
      return setMemberData({ ...memberData, [e.target.name]: null })
    }

    setMemberData({ ...memberData, [e.target.name]: e.target.value })
    console.log({ memberData })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { cell, name, email, title, role, team, stack } = memberData;
    const { id, token, organization } = user;

    // todo, just pass in user
    const result = await createUser({ cell, name, email, title, team, organization_role: role, stack, user_id: id, token, org_id: organization })
    console.log({ result })
    navigate(-1)
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8">
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">New Member</h3>
              </div>

              <div className="pb-12">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Name
                    </label>
                    <div className="mt-2">
                      <input
                        id="name"
                        name="name"
                        onChange={handleChange}
                        type="name"
                        autoComplete="name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Organization Role
                    </label>
                    <div className="mt-2">
                      <select
                        id="role"
                        name="role"
                        autoComplete="role"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>member</option>
                        <option>admin</option>
                        <option>owner</option>
                      </select>
                    </div>
                  </div>


                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Title
                    </label>
                    <div className="mt-2">
                      <select
                        id="title"
                        name="title"
                        onChange={handleChange}
                        autoComplete="title"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Intern</option>
                        <option>L1</option>
                        <option>L2</option>
                        <option>L3</option>
                        <option>TD</option>
                        <option>Staff</option>
                        <option>SRE</option>
                        <option>SysAdmin</option>
                        <option>SQA</option>
                        <option>PM</option>
                        <option>Manager</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Team
                    </label>
                    <div className="mt-2">
                      <select
                        id="team"
                        name="team"
                        onChange={handleChange}
                        autoComplete="team"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        {teams.map(team => {
                          return (
                            <option key={team._id} value={team._id}>{team.name}</option>
                          )
                        })}
                        <option key={null} value={null}>None</option>

                      </select>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Stack
                    </label>
                    <div className="mt-2">
                      <select
                        id="stack"
                        name="stack"
                        onChange={handleChange}
                        autoComplete="stack"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>SQA</option>
                        <option>front-end</option>
                        <option>back-end</option>
                        <option>devops</option>
                        <option>product</option>
                        <option>full-stack</option>
                        <option>tech-desk</option>
                      </select>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                      Cell Number
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="cell"
                        onChange={handleChange}
                        id="cell"
                        autoComplete="cell"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                {updating ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
