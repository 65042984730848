import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon, PlusCircleIcon, MinusCircleIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import moment from "moment-timezone";
const TIMEZONE = process.env.REACT_APP_TIMEZONE;
import Pagination from "../components/Pagination";
import { getUser } from "../api/users";
import { getUserAssignments } from "../api/assignments";
import { getUserNotifications } from "../api/notifications";
import Loading from "../components/Loading";

// todo:
// add loading screen
// make card header better
// todo fix delete assignment

// if no current assignments show, don't load
// show upcoming assignments, open by default
// show issues, differentiate active, open by default
// change notifications to call when opened
// add stats table to top of page
// - active issues
// - average response time
// - num notifications recieved to date
// - num assignments completed to date
// fix mobile view

// get  unack'd team issues
// get user notifications
// get upcoming assignments

export default function Home(args) {
  const user = useUser();
  const navigate = useNavigate();
  const { org_id, team_id } = useParams();
  const user_is_admin = user.organization_role != 'member';
  const [loading, setLoading] = useState(false)
  const [team, setTeam] = useState({})

  const [notifications, setNotifications] = useState([])
  const [notificationsLoading, setNotificationsLoading] = useState(false)
  const [notificationPageNumber, setNotificationPageNumber] = useState(0)
  const [notificationCount, setNotificationCount] = useState(5)

  const [assignments, setAssignments] = useState([])
  const [assignmentsLoading, setAssignmentsLoading] = useState(false)

  const isCurrentAssignment = (assignment) => {
    return moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())
  }

  const fetchUserNotifications = async () => {
    setNotificationsLoading(true)
    const response = await getUserNotifications({ token: user.token, user_id: user.id, page_number: notificationPageNumber, count: notificationCount })
    if (!response) return;
    setNotifications([...response.data])
    setNotificationsLoading(false)
  }

  const fetchUserAssignments = async () => {
    setLoading(true)
    const response = await getUserAssignments({ token: user.token, user_id: user.id })
    if (!response) return;
    setAssignments([...response.data].filter(assignment => !moment(assignment.end_date).isBefore(moment())))
  }

  useEffect(() => {
    fetchUserNotifications()
    fetchUserAssignments()
  }, [])


  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="px-4 pt-8 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col">
            <Link to={`/profile/${user.id}`} className="text-base font-semibold text-xl leading-6 text-gray-900">{user?.name}</Link>

            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <NotificationsTable
                  notifications={notifications}
                  count={notificationCount}
                  setCount={setNotificationCount}
                  pageNumber={notificationPageNumber}
                  setPageNumber={setNotificationPageNumber}
                  defaultOpen={true}
                  loading={notificationsLoading}
                />
                <AssignmentTable
                  assignment_type={'Assignments'}
                  assignments={assignments}
                  user_is_admin={user_is_admin}
                  user={user}
                  isCurrentAssignment={isCurrentAssignment}
                  defaultOpen={true}
                  loading={assignmentsLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

// assignments - add assignment
// issues
// notifications
// members - add member, - edit member, - remove member from org/team

function AssignmentTable({ loading, user, defaultOpen, assignment_type, assignments, user_is_admin, isCurrentAssignment, navigate, showCreateButton = false, createButtonLink = null }) {
  const [showTable, setShowTable] = useState(defaultOpen)
  return (
    <>
      <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
        <div className="p-4 overflow-x-auto ">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="flex justify-between">
                <div className="flex">
                  {(!loading && !assignments.length)
                    ?

                    <>
                      <HandThumbUpIcon className="h-6 w-6" />
                      <h1 className="ml-4 text-base font-semibold leading-6 text-gray-900"> No upcoming assignments ...Whew!!</h1></>
                    : <>
                      < div
                        className="pr-4 hover:text-emerald-600"
                        onClick={() => setShowTable(!showTable)}
                      >
                        {!showTable
                          ? <PlusCircleIcon className="h-6 w-6" />
                          : <MinusCircleIcon className="h-6 w-6" />
                        }
                      </div>
                      <h1 className="text-base font-semibold leading-6 text-gray-900">{assignment_type}</h1>
                    </>
                  }
                </div>

                {showCreateButton &&
                  <div>
                    {user_is_admin && <button
                      type="button"
                      onClick={() => navigate(createButtonLink)}
                      className=" inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
                    >
                      +
                    </button>}
                  </div>
                }
              </div>
            </div>
            {showTable && <div >
              {loading && <Loading />}
              {(!loading && !!assignments.length) &&
                <div className="overflow-hidden ">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Team
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Start
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          End
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {assignments?.map((assignment, i) => (
                        <tr key={i} className={`${isCurrentAssignment(assignment) && 'border-b border-emerald-200 '}`}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                            <Link className="text-emerald-600 hover:text-emerald-900" to={`/teams/${assignment?.team?._id}`}>

                              {assignment?.team?.name}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {moment(assignment?.start_date)?.tz(TIMEZONE).format('MM/DD/YYYY') || 'test'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment(assignment?.end_date)?.tz(TIMEZONE).format('MM/DD/YYYY') || 'test'}
                          </td>
                        </tr>))
                      }
                    </tbody>
                  </table>
                </div>
              }
            </div>
            }

          </div>
        </div>
      </div >
    </>
  )
}


function NotificationsTable({ loading, defaultOpen, notifications, count, setCount, pageNumber, setPageNumber }) {
  const [showTable, setShowTable] = useState(defaultOpen)
  return (
    <div className="mt-8 flex flex-col shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border border-gray-200 rounded-md">
      <div className="p-4 overflow-x-auto ">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="my-4">
            <div className="flex justify-between">
              <div className="flex">
                {(!loading && !notifications.length)
                  ? <>
                    <HandThumbUpIcon className="h-6 w-6" />
                    <h1 className="ml-4 text-base font-semibold leading-6 text-gray-900"> No new notifications...That's nice!</h1></>
                  : <>
                    <div
                      className="pr-4 hover:text-emerald-600"
                      onClick={() => setShowTable(!showTable)}
                    >
                      {!showTable
                        ? <PlusCircleIcon className="h-6 w-6" />
                        : <MinusCircleIcon className="h-6 w-6" />
                      }
                    </div>
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Notifications</h1>
                  </>
                }
              </div>
            </div>
          </div>
          {showTable && <div>
            {loading && <Loading />}
            {(!loading && !!notifications.length) && <div>
              <table className="overflow-hidden min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Issue ID
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Cell
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Date Sent
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Time Sent
                    </th>
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {notifications?.map((notification, i) => (
                    <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                        {(!!notification?.issue)
                          ? <a className="text-emerald-600 hover:text-emerald-900" href={notification.issue.issue_url}>
                            {(notification?.issue?._id).slice(-4)}
                          </a>
                          : <>{(notification?.issue_id).slice(-4)}</>
                        }
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                        {notification?.user?.name}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                        {notification?.user?.cell}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                        {moment(notification?.date_created).format('MM/DD/YYYY')}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                        {moment.utc(notification?.date_created).local().format('hh:mm:ss A')}
                      </td>
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="flex justify-end mr-2 mt-4">
                <Pagination
                  count={count}
                  setCount={setCount}
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                />
              </div>
            </div>
            }
          </div>
          }
        </div>
      </div>
    </div >
  )
}
