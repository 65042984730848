import { URL } from ".";
import { toast } from "react-hot-toast";

const getOrgMembers = async ({ user_id, org_id, token }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving members' }
  }
}

const getOrgTeams = async ({ user_id, org_id, token }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/teams`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving teams' }
  }
}

const createUser = async ({ cell, name, email, title, team, organization_role, stack, user_id, token, org_id }) => {
  try {

    console.log('in create orr')
    const response = await fetch(
      `${URL}/org/${org_id}/users/new`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          cell,
          name,
          email,
          title,
          organization_role,
          stack,
          team
        })
      });
    if (!response.ok) throw new Error('error creating member');

    toast.success('Member created successfully')
    return await response.json();
  } catch (error) {
    alert(error);
    toast.error('Member created successfully')
    return null
  }
}

const createTeam = async ({ name, token, org_id }) => {
  try {
    const response = await fetch(`${URL}/org/${org_id}/teams/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name,
      })
    });
    if (!response.ok) throw new Error('error creating team');

    toast.success('Team created successfully')
    return await response.json();
  } catch (error) {
    toast.error('Error createing team')
    return null
  }
}

export { getOrgMembers, createUser, createTeam, getOrgTeams }

