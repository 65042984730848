import React, { useEffect, useState, Fragment } from "react";
import { useUser, useUserDispatch } from '../contexts/user';
import { getUserAssignments } from "../api/assignments";
import AssignmentsList from "../components/AssignmentsList";
import MemberPanel from "../components/MemberPanel";
import AssignmentPanel from "../components/AssignmentPanel";
import NotificationsList from "../components/NotificationsList";
import { getUserNotifications } from "../api/notifications";
import { useParams } from 'react-router-dom';
import moment from "moment";
import Pagination from "../components/Pagination";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NotificationsPage() {
  const user = useUser()
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const { user_id } = useParams()
  const [pageNumber, setPageNumber] = useState(0)
  const [count, setCount] = useState(10)

  const paginationClicked = ({ direction }) => {

    if (direction === 'next') {
      setPageNumber(pageNumber + 1)
    } else if (direction === 'previous' && pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  const fetchNotifications = async (page_number = 0, count = 10) => {
    setLoading(true)
    console.log('fetching notis')
    const response = await getUserNotifications({ token: user.token, user_id: user_id, page_number, count })
    console.log({ response })
    if (!response.data.length) return;
    setNotifications([...response.data].sort((a, b) => moment(b.date_created).isAfter(moment(a.date_created))))
    setLoading(false)
  }

  useEffect(() => {
    fetchNotifications(pageNumber, count)
  }, [setNotifications, pageNumber])

  return (
    <>
      {loading
        ? <>Loading...</>
        : <div>
          {notifications?.length
            ? <NotificationsUI
              notifications={notifications}
              user_id={user_id}
              paginationClicked={paginationClicked}
              count={count}
              setCount={setCount}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}

            />
            : <NoNotificationsUI />
          }
        </div>
      }
    </>
  )

}

const NotificationsUI = ({ notifications, user_id, paginationClicked, count, setCount, pageNumber, setPageNumber }) => {
  return (
    <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Notifications</h1>
          </div>
          <Pagination
            count={count}
            setCount={setCount}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              onClick={() => paginationClicked({ direction: 'previous' })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>
          </div>
          <div className="mt-4 sm:ml-1 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              onClick={() => paginationClicked({ direction: 'next' })}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div> */}
        </div>

        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Issue ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Source
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Assignee Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Assignee Cell
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Created
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Time Created
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Ack'd
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {notifications?.map((notification, i) => (
                      <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {(!!notification?.issue)
                            ? <a className="text-emerald-600 hover:text-emerald-900 hover:underline" href={notification.issue.issue_url}>
                              {(notification?.issue?._id).slice(-4)}
                            </a>
                            : <>{(notification?.issue_id).slice(-4)} 1</>
                          }

                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {notification?.issue?.source}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {notification?.user.name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {notification?.user.cell}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {moment(notification?.date_created).format('MM/DD/YYYY')}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {moment.utc(notification?.date_created).local().format('hh:mm:ss A')}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {(notification?.issue?.acknowledged)
                            ? <span className="text-green-600">True</span>
                            : <span className="text-red-600">False</span>
                          }
                        </td>

                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const NoNotificationsUI = () => {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Notifications</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Notification details</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <p className="mt-2  text-sm leading-6 text-red-500">No notifications found.</p>
      </div>
    </>
  )
}



