import React, { useEffect, useState, Fragment } from "react";
import { useUser } from '../contexts/user';
import { getOrgTeams } from "../api/organizations";
import { createTestIssue } from "../api/issues";
import { useNavigate, useParams } from 'react-router-dom'


export default function AdminPage() {
  const { org_id } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const [teams, setTeams] = useState([])
  const user_is_admin = user.organization_role != 'member';
  const [loading, setLoading] = useState(false)
  const [issueData, setIssueData] = useState({
    org_id,
  })

  if (!user_is_admin) {
    navigate(-1)
  }

  const handleChange = (e) => {
    if (e.target.value == 'None') {
      return setMemberData({ ...memberData, [e.target.name]: null })
    }

    setIssueData({ ...issueData, [e.target.name]: e.target.value })
    console.log({ issueData })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { org_id, team_id } = issueData
    const { token } = user;

    const result = await createTestIssue({ token, org_id, team_id })
    console.log({ result })
  }


  // get teams, have team ids to pass into form
  // pass org id into form
  // create API call

  const fetchOrgTeams = async () => {
    setLoading(true)
    const response = await getOrgTeams({ token: user.token, user_id: user.id, org_id })
    if (!response) return;
    setTeams([...response.data])
    setIssueData({ ...issueData, team_id: response.data[0]._id })
    setLoading(false)
  }

  useEffect(() => {
    fetchOrgTeams()
  }, [])



  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
          <div className="md:flex md:items-center md:justify-between">
            Admin Panel
          </div>
        </div>
        <button>Create Test Issue</button>
        <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8">
              <div className="pt-8">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Create Test Issue </h3>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                  <div className="sm:col-span-3">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Team
                    </label>
                    <div className="mt-2">
                      <select
                        id="team_id"
                        name="team_id"
                        autoComplete="team_id"
                        onChange={handleChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        {teams.map(team => {
                          return <option key={team._id} value={team._id}>{team.name}</option>
                        })}
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  onClick={() => navigate(-1)}
                  type="button"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                >
                  Go
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
