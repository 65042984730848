import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getIssue } from "../api/issues";
import { getIssueNotifications } from "../api/notifications";
import { useUser } from "../contexts/user";
import IssueTimeline from "../components/IssueTimeline";

export default function IssuePage() {
  const user = useUser();
  const navigate = useNavigate()
  const [issue, setIssue] = useState(null)
  const [issueNotifications, setIssueNotifications] = useState(null)
  const [loading, setLoading] = useState(false)
  const { issue_id } = useParams()
  const fetchIssue = async () => {
    setLoading(true)

    const response = await getIssue({ token: user.token, issue_id })
    if (!response) return;
    setIssue({ ...response.data })
    setLoading(false)
  }

  const fetchIssueNotifications = async () => {
    setLoading(true)
    const response = await getIssueNotifications({ token: user.token, issue_id })
    console.log('response', response)
    if (!response) return;
    setIssueNotifications([...response.data])
    setLoading(false)
  }

  useEffect(() => {
    fetchIssue()
    fetchIssueNotifications()
  }, [issue_id])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          {loading
            ? <>Loading...</>
            : <div>
              {issue ? <Issue issue={issue} issueNotifications={issueNotifications} /> : <NoProfileUI />}
            </div>
          }
        </div>
      </div>
    </>

  )
}

function Issue({ issue, issueNotifications }) {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Issue Details </h3>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Issue</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{issue?.issue_title} </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Source</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <a className="text-black hover:text-emerald-900" href={issue.issue_url}>
                {issue?.source}
              </a>
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Date Created</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{issue?.date_created}</dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Acknowledged</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{issue?.acknowledged ? 'True' : 'False'}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Team</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Link className="text-black hover:text-emerald-900" to={`/teams/${issue?.team?._id}`}>
                {issue?.team?.name}
              </Link>

            </dd>
          </div>
        </dl>
      </div>
      <h3 className="text-base font-semibold leading-7 text-gray-900 py-4">Issue Timeline </h3>
      {!!issueNotifications ? <IssueTimeline notifications={issueNotifications} /> : null}
    </>

  )
}

function NoProfileUI() {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Profile </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Contact details and role information.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <p className="mt-2  text-sm leading-6 text-red-500">No profile found.</p>
      </div>
    </>
  )
}
