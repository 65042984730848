import { URL } from ".";
import { toast } from "react-hot-toast";

const getUserAssignments = async ({ token, user_id }) => {

  try {
    const response = await fetch(`${URL}/user/${user_id}/assignments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}

const getOrgAssignments = async ({ token, org_id }) => {
  try {
    const response = await fetch(`${URL}/orgs/${org_id}/assignments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}

const createAssignment = async ({ assignment, token, team_id }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/assignments/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        assignment
      })
    });

    if (!response.ok) throw new Error('Error creating assignment.')

    toast.success('Assignment created successfully.')
    return await response.json();
  } catch (error) {
    toast.error(error);
    return null
  }
}

const getTeamAssignments = async ({ team_id, token }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/assignments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving team' }
  }
}

const deleteAssignment = async ({ token, assignment_id }) => {
  try {
    const response = await fetch(`${URL}/assignments/${assignment_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });

    toast.success('Assignment deleted successfully.')
    const result = await response?.json()
    return result
  } catch (error) {
    console.log('deleteAssignment error', error)
    toast.error(error);
    return null
  }
}

export { getUserAssignments, createAssignment, getOrgAssignments, getTeamAssignments, deleteAssignment }

