import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/user";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getOrgIssues } from "../api/issues";
import Pagination from "../components/Pagination";

export default function issuesList() {
  const user = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [issues, setIssues] = useState([])
  const [count, setCount] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)

  const fetchIssues = async (page_number = 0, count = 10) => {
    setLoading(true)
    const response = await getOrgIssues({ token: user.token, user_id: user.id, org_id: user.organization, page_number, count })
    if (!response?.data) return;
    setIssues([...response.data].sort((a, b) => moment(a.date_created).isBefore(moment(b.date_created))))
    setLoading(false)
  }

  useEffect(() => {
    fetchIssues(pageNumber, count)
  }, [setIssues, pageNumber])

  return (
    <>
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="flex align-center">
            <div className="mr-2">
              <button
                type="button"
                className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              // onClick={() => ()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </button>
            </div>

            <Pagination
              count={count}
              setCount={setCount}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
            />

          </div>
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Issue ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Source
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Created
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Ack'd At
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Ack'd By
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {issues?.map((issue, i) => (
                      <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <a className="text-emerald-600 hover:text-emerald-900" href={issue.issue_url}>
                            {(issue?._id).slice(-4)}
                          </a>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {issue?.source}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment.utc(issue?.date_created).local().format('MM/DD/YY hh:mm:ss A')}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {issue?.acknowledged ? moment.utc(issue?.acknowledged_at).local().format('MM/DD/YY hh:mm:ss A') : 'unacknowledged'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {(!!issue?.acknowledged_by)
                            ? <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${issue?.acknowledged_by._id}`}>{issue?.acknowledged_by?.name}</Link>
                            : 'N/A'
                          }
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
} 