import React, { useState, useEffect } from "react"
import { useUser, useUserDispatch } from "../contexts/user";
import { addMembersToTeam } from "../api/teams";
import { useNavigate } from 'react-router-dom'
import { getOrgMembers } from "../api/organizations";
import { useParams } from "react-router-dom";

export default function AddMemberToTeam(args) {
  const user = useUser();
  const { token, organization, id } = user;
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const { team_id } = useParams();
  const [loading, setLoading] = useState(false)
  const [updating] = useState(false)
  const [members, setMembers] = useState([])
  const [teamMembers, setTeamMembers] = useState([])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const fetchMembers = async () => {
    setLoading(true)
    const response = await getOrgMembers({ token, user_id: id, org_id: organization })
    if (!response) return;
    console.log('org members', response.data)
    console.log(response.data[0]?.team?._id, 'team_id of member')
    console.log(team_id, 'team_id')
    setMembers([...response.data.filter(member => member?.team?._id != team_id)]); // todo check if user is in team, rather than team on user
    setTeamMembers([...response.data.filter(member => member?.team?._id == team_id)]);
    console.log('org: ', [...response.data.filter(member => member?.team?._id != team_id)]); // todo check if user is in team, rather than team on user
    console.log('team: ', [...response.data.filter(member => member?.team?._id == team_id)]);

    console.log({ members, teamMembers })
    setLoading(false)
  }

  useEffect(() => {
    fetchMembers()
  }, [team_id])


  const handleChange = (member_id) => {
    const member = teamMembers.find(m => m._id === member_id);
    if (member) {
      setTeamMembers(teamMembers.filter(m => m._id !== member_id));
      setMembers([...members, member]);
      console.log('remove', { teamMembers, members })
      return;
    }

    const memberToAdd = members.find(m => m._id === member_id);
    if (memberToAdd) {
      setTeamMembers([...teamMembers, memberToAdd]);
      setMembers(members.filter(m => m._id !== member_id));
      console.log('add', { teamMembers, members })

    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addMembersToTeam({ members: teamMembers.map(tm => tm._id), token, user_id: id, org_id: organization, team_id })
    // navigate(-1)
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 mb-8">
          <div className="space-y-8">
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Add Team Member </h3>
                <p className="mt-1 text-sm text-gray-500">Select a to add to your team</p>
              </div>
            </div>
            <div className="mt-6">
              Org Members
              <div>
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {members.map((member) => (
                    <li key={member.email}
                      onClick={() => handleChange(member._id)}
                      className="col-span-1 flex rounded-md shadow-sm">
                      <div
                        className={classNames(
                          `flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white bg-emerald-500`
                        )}
                      >
                        {member.email[0]}
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:border-blue-500">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <a href='#' className="font-medium text-gray-900 hover:text-gray-600">
                            {member.name}
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                Team Members
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                  {teamMembers.map((member) => (
                    <li key={member.email}
                      onClick={() => handleChange(member._id)}
                      className="col-span-1 flex rounded-md shadow-sm">
                      <div
                        className={classNames(
                          `flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white bg-emerald-500`
                        )}
                      >
                        {member.email[0]}
                      </div>
                      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:border-blue-500">
                        <div className="flex-1 truncate px-4 py-2 text-sm">
                          <a href='#' className="font-medium text-gray-900 hover:text-gray-600">
                            {member.name}
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                {updating ? 'Update' : 'Save'}
              </button>
            </div>
          </div>

        </form>
      </div>
    </>
  )
}
