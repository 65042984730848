import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/user";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getOrgNotifications } from "../api/notifications";
import Pagination from '../components/Pagination'

export default function NotificationsList() {
  const user = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [count, setCount] = useState(10)
  const [pageNumber, setPageNumber] = useState(0)


  const fetchNotifications = async (page_number = 0, count = 10) => {
    setLoading(true)
    const response = await getOrgNotifications({ token: user.token, user_id: user.id, org_id: user.organization, page_number, count })
    if (!response) return;
    setNotifications([...response.data].sort((a, b) => moment(a.date_created).isBefore(moment(b.date_created))))
    setLoading(false)
  }

  useEffect(() => {
    fetchNotifications(pageNumber, count)
  }, [setNotifications, pageNumber])

  return (
    <>
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <Pagination
            count={count}
            setCount={setCount}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Issue ID
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Assignee Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Assignee Cell
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Date Sent
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Time Sent
                      </th>
                      {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {notifications?.map((notification, i) => (
                      <tr key={i} className={`hover:bg-blue-100 cursor-pointer`}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {(!!notification?.issue)
                            ? <a className="text-emerald-600 hover:text-emerald-900" href={notification.issue.issue_url}>
                              {(notification?.issue?._id).slice(-4)}
                            </a>
                            : <>{(notification?.issue_id).slice(-4)}</>
                          }
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {notification?.user?.name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {notification?.user?.cell}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {moment(notification?.date_created).format('MM/DD/YYYY')}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-6">
                          {moment.utc(notification?.date_created).local().format('hh:mm:ss A')}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                        {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                          Delete
                        </p>
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
} 