import { URL } from ".";

const getIssueNotifications = async ({ token, issue_id }) => {
  try {
    const response = await fetch(`${URL}/issue/${issue_id}/notifications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
const getOrgNotifications = async ({ token, org_id, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/org/${org_id}/notifications?page_number=${page_number}&count=${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}

const getTeamNotifications = async ({ team_id, token, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/notifications?page_number=${page_number}&count=${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving team' }
  }
}

const getUserNotifications = async ({ token, user_id, page_number, count }) => {

  try {
    const response = await fetch(`${URL}/user/${user_id}/notifications?page_number=${page_number}&count=${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}


export { getOrgNotifications, getUserNotifications, getTeamNotifications, getIssueNotifications }