import { URL } from ".";

const getIssue = async ({ token, issue_id }) => {
  try {
    const response = await fetch(`${URL}/issues/${issue_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
const getTeamIssues = async ({ user_id, token, org_id, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/issues/page_number/${page_number}/count/${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
const getUserIssues = async ({ user_id, token, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/issues/page_number/${page_number}/count/${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
const createTestIssue = async ({ token, org_id, team_id }) => {
  console.log({ token, org_id, team_id })
  try {
    const response = await fetch(`${URL}/org/${org_id}/issues/test`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({ team_id })
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}
const getOrgIssues = async ({ token, org_id, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/org/${org_id}/issues?page_number=${page_number}&count=${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return null
  }
}

export { getOrgIssues, getTeamIssues, getUserIssues, getIssue, createTestIssue }