import React, { useState, useEffect } from "react"
import { useUser, useUserDispatch } from "../contexts/user";
import moment from "moment";
import { createAssignment } from "../api/assignments";
import { useNavigate, useParams } from 'react-router-dom'
import { getOrgMembers } from "../api/organizations";
import { getTeam } from "../api/teams";
import { createToken } from "../api/tokens"
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import toast from "react-hot-toast";

const remote_url = process.env.REACT_APP_SERVERLESS_URL;

export default function TokenForm(args) {
  const user = useUser();
  const navigate = useNavigate();
  const { team_id } = useParams();
  const [loading, setLoading] = useState(false)
  const [updating] = useState(false)
  const [token, setToken] = useState(null)
  const [tokenData, setTokenData] = useState({
    source: null,
  });


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const copyToClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(`${remote_url}/issues/new?token=${token}`);
      toast.success('Copied to clipboard')
    } catch (error) {
      toast.error('Unable to copy to clipboard')
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setTokenData({ ...tokenData, [name]: value });
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { token, id, organization } = user;
    const { source } = tokenData;

    const result = await createToken({ token, source, org_id: organization, team_id })
    console.log({ result })
    setToken(result?.data?.token)
  }


  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="pt-8">
          <div>
            <h3 className="text-lg font-medium mb-4 leading-6 text-gray-900">Create Token</h3>
          </div>
        </div>
        {token && <div className="w-xl">
          <div className="mt-8 flex flex-col shadow ring-1 ring-gray-100 border border-gray-200 rounded-md">
            <h3 className="text-xs  break-words justify leading-6 text-gray-900 m-4">{remote_url}/issues/new?token={token}</h3>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Done
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                onClick={copyToClipboard}
              >
                <DocumentDuplicateIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Copy Endpoint
              </button>
            </div>
          </div>

        </div>}

        {!token && <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 mb-8">
          <div className="space-y-8">
            <div className="mt-6">
              <fieldset className="mt-6">
                <label htmlFor="recipient-name" className="block text-sm font-medium text-gray-700">
                  Source - use lower case names with underscores instead of spaces, e.g. new_relic, data_dog, click_uo
                </label>
                <div className="mt-1">
                  <input
                    id="source"
                    name="source"
                    onChange={handleChange}
                    value={tokenData.source}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  />
                </div>
              </fieldset>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Create
              </button>
            </div>
          </div>
        </form>}
      </div>
    </>
  )
}
