import React, { useEffect } from 'react'
import { HashRouter as Router, Route, Routes, RouterProvider, createBrowserRouter, useNavigate } from 'react-router-dom'
import WelcomePage from './pages/Welcome'
import HomePage from './pages/Home'
import ProfilePage from './pages/Profile'
import AssignmentsPage from './pages/Assignments'
import AdminPage from './pages/Admin'
import OrgPage from './pages/Org'
import Team from './components/Team'
import AuthForm from './components/AuthForm'
import ForgotPassword from './components/ForgotPassword'
import Navbar from './components/Nav'
import { UserProvider, useUser } from './contexts/user';
import RequireAuth from './middleware/requireAuth';
import RequireAdmin from './middleware/requireAdmin';
import NotificationsPage from './pages/Notifications'
import SettingsPage from './pages/Settings'
import { Toaster } from 'react-hot-toast';
import TeamForm from './components/TeamForm'
import TeamList from './components/TeamList'
import MemberList from './components/MemberList'
import NotificationsList from './components/NotificationsList'
import IssuesList from './components/IssuesList'
import IssuePage from './pages/Issue'
import AssignmentsList from './components/AssignmentsList'
import MemberForm from './components/MemberForm'
import AssignmentForm from './components/AssignmentForm'
import AddMemberToTeam from './components/AddMemberToTeam'
import TokenForm from './components/TokenForm'



function App() {
  return (
    <UserProvider>
      <Toaster />
      <Router>
        <Content />
      </Router>
    </UserProvider >
  );
}

function Content() {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user) {
      navigate(`/home/${user.id}`)
    }
  }, [user])

  return (
    <>
      {user
        ? <>
          <RequireAuth>
            <Navbar />
            <Routes>
              {/* <Route path="/dashboard" element={<DashboardPage />}></Route> */}
              <Route path="/home/:user_id" element={<HomePage />}></Route>
              <Route path="/profile/:user_id" element={<ProfilePage />}></Route>
              <Route path="/assignments/:user_id" element={<AssignmentsPage />}></Route>
              <Route path="/notifications/:user_id" element={<NotificationsPage />}></Route>
              <Route path="/settings" element={<SettingsPage />}></Route>
              <Route path="issues/:issue_id" element={<IssuePage />} />
              <Route path="/teams/:team_id" element={<Team />} />
              <Route path="/teams/:team_id/tokens/new" element={<TokenForm />} />
              <Route path="/teams/:team_id/assignments/new" element={<AssignmentForm />} />
              <Route path="/teams/:team_id/members/new" element={<AddMemberToTeam />} />
              <Route path="/org/:org_ig/teams/new" element={<TeamForm />} />
              <Route path="/org/:org_id/members/new" element={<MemberForm />} />
              <Route path="/org/:org_id/admin" element={<AdminPage />} />
              <Route path="/org/:org_id" element={<OrgPage />}>
                <Route path="teams" element={<TeamList />} />
                <Route path="teams/new" element={<TeamForm />} />
                <Route path="teams/:team_id" element={<Team />} />
                <Route path="members" element={<MemberList />} />
                <Route path="notifications" element={<NotificationsList />} />
                <Route path="issues" element={<IssuesList />} />
                <Route path="assignments" element={<AssignmentsList />} />
                <Route path="assignments/new" element={<AssignmentForm />} />
              </Route>

              {/* <Route path="/admin" element={
                <RequireAdmin>
                  <AdminPage />
                </ RequireAdmin>
              }></Route> */}
            </Routes>
          </RequireAuth>
        </>
        :
        <Routes>
          <Route exact path="/signup" element={<AuthForm form="signup" buttonText="Sign Up!" heading="Sign up to get started" />} />
          <Route exact path="/signin" element={<AuthForm form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route exact path="/forgot_password" element={<ForgotPassword form="signin" buttonText="Sign In!" heading="Sign in to your account" />} />
          <Route path="*" element={<WelcomePage />}></Route>
        </Routes>
      }
    </>
  );
}

export default App;