import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signin, signup } from "../api/authentication";
import { useUserDispatch } from "../contexts/user";
import { Link } from 'react-router-dom';

const AuthForm = (props) => {
  const dispatch = useUserDispatch();
  const navigate = useNavigate();
  const [isSignupForm, setIsSignUpForm] = useState(props?.form === 'signup')
  const [userData, setUserData] = useState({
    email: "",
    password: ""
  });

  useEffect(() => {
    setIsSignUpForm(props?.form === 'signup')
  }, [props?.form])

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e, dispatch) => {
    e.preventDefault();
    try {
      let response;
      if (props?.form === "signin") {
        response = await signin({ email: userData.email, password: userData.password })
      } else if (props?.form === "signup") {
        response = await signup({ email: userData.email, password: userData.password })
      }

      if (!response?.data?.token) {
        throw new Error(`error signing ${props?.form === 'signin' ? 'in' : 'up'}`)
      }
      const { data } = response;
      dispatch({
        type: 'updated',
        user: { ...data }
      })
      navigate(`/home/${data?.id}`)
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <div className="flex flex-col items-center mx-auto sm:px-6 lg:px-8 h-screen bg-gray-50">
        <div className="flex mx-auto w-full mt-8 sm:px-6 lg:px-8 justify-end">
          <button
            type="button"
            onClick={() => navigate('/')}
            className="inline-flex items-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          >
            Home
          </button>
        </div>
        <div className="mx-auto w-full sm:px-6 lg:px-8 h-1/2">
          <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
              <div>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  {props.heading}
                </h2>
              </div>
              <form onSubmit={(event) => { handleSubmit(event, dispatch) }} className="mt-8 space-y-6">
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="-space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={userData.email}
                      type="text"
                      autoComplete="email"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      onChange={handleChange}
                      value={userData.password}
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      required
                      className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                      placeholder="Password"
                    />
                  </div>
                </div>

                {/* <div className="flex items-center justify-between">
              <div className="text-sm">
                <a href="#" className="font-medium text-emerald-600 hover:text-emerald-500">
                  Forgot your password?
                </a>
              </div>
            </div> */}

                <div>
                  <button
                    type="submit"
                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  >
                    {/* <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-emerald-500 group-hover:text-emerald-400" aria-hidden="true" />
                    </span> */}
                    {props?.buttonText || 'Go!'}
                  </button>
                  <p className="mt-6 text-xs justify-center flex leading-5 text-gray-600">
                    {isSignupForm
                      ? <>Already have an account?<span><Link className="pl-1 text-emerald-600 hover:underline" to="/signin"> Sign In</Link></span></>
                      : <div className="text-center">
                        <div>Need to sign up first?<span><Link className="pl-1 text-emerald-600 hover:underline" to="/signup"> Sign up</Link></span></div>
                        <div>Forgot your password<span><Link className="pl-1 text-emerald-600 hover:underline" to="/forgot_password">Forgot Password</Link></span></div>
                      </div>
                    }
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default AuthForm;