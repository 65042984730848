import { URL } from ".";
import { toast } from "react-hot-toast";

const createToken = async ({ token, source, org_id, team_id }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/tokens/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        org_id,
        source
      })
    });

    if (!response.ok) throw new Error('Error creating token.')

    toast.success('Token created successfully.')
    return await response.json();
  } catch (error) {
    toast.error(error);
    return null
  }
}

export { createToken }
