import React, { useState, useEffect } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

export default function Pagination({ setPageNumber, pageNumber, count, setCount }) {

  const paginationClicked = ({ direction }) => {
    if (direction === 'next') {
      setPageNumber(pageNumber + 1)
    } else if (direction === 'previous' && pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  return (
    <div className="flex justify-end my-2">
      <div className="">
        <button
          type="button"
          className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          onClick={() => paginationClicked({ direction: 'previous' })}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
      </div>
      <div className="ml-1  sm:flex-none">
        <button
          type="button"
          className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          onClick={() => paginationClicked({ direction: 'next' })}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  )
}