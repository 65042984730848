import { URL } from ".";
import { toast } from "react-hot-toast";

const getUser = async ({ token, user_id }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    toast.error(error);
    return null
  }
}
const updateUser = async ({ cell, name, email, user_id, token }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name,
        email,
        cell
      })
    });
    return await response.json();
  } catch (error) {
    console.log({ error })
    toast.error(error);
    return null
  }
}

const getOrgUsers = async ({ token, user_id, org_id }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    toast.error(error);
    return null
  }
}

export { getUser, getOrgUsers, updateUser }
