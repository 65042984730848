import React, { useEffect, useState } from "react";
import moment from "moment";
import { useUser } from '../contexts/user';
import { getUserAssignments } from "../api/assignments";
import { useParams } from 'react-router-dom';

export default function AssignmentsPage() {
  const user = useUser()
  const [upcomingAssignments, setUpcomingAssignments] = useState([])
  const [currentAssignments, setCurrentAssignments] = useState([])
  const [pastAssignments, setPastAssignments] = useState([])
  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(false)
  const { user_id } = useParams()

  const setAllAssignments = (assignments) => {
    setUpcomingAssignments(assignments.filter(assignment => moment(assignment.start_date).isAfter(moment())))
    setCurrentAssignments(assignments.filter(assignment => moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())))
    setPastAssignments(assignments.filter(assignment => moment(assignment.end_date).isBefore(moment())))
  }

  const fetchAssignments = async () => {
    setLoading(true)
    const response = await getUserAssignments({ token: user.token, user_id: user_id })
    if (!response) return;
    setAllAssignments([...response.data])
    setLoading(false)
  }

  useEffect(() => {
    fetchAssignments()
  }, [setUpcomingAssignments, setCurrentAssignments, setPastAssignments])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-12 sm:px-6 lg:px-8">
        {loading
          ? <>Loading...</>
          : <div>
            {(currentAssignments?.length || upcomingAssignments?.length || pastAssignments?.length) ? <>
              <ul role="list" className="space-y-3">
                {[
                  { assignments: currentAssignments, title: 'Current Assignments', type: 'current' },
                  { assignments: upcomingAssignments, title: 'Upcoming Assignments', type: 'upcoming' },
                  { assignments: pastAssignments, title: 'Past Assignments', type: 'past' }
                ].filter(item => item.assignments.length).map((item, i) => (
                  <li key={i} className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                    {console.log(item)}
                    <Example title={item.title} assignments={item.assignments} type={item.type} />
                  </li>
                ))}
              </ul>
            </> : <NoAssignmentUI />}
          </div>
        }
      </div>
    </>
  )
}

function AssignmentUI({ assignments }) {
  return (
    <div>
      <h2 className="text-sm font-medium text-gray-500">Assignments</h2>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
        {assignments.map((assignment, i) => (
          < li key={i} className="col-span-1 flex rounded-md shadow-sm" >
            <div
              className={
                'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white bg-blue-500'
              }
            >
              {i + 1}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                Assignment {i + 1}
                <p className="text-gray-500">{moment(assignment.start_date).format('MM/DD/YY')} - {moment(assignment.end_date).format('MM/DD/YY')}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div >
  )
}


function Example({ title, assignments, type }) {

  return (

    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{title}</h1>
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inlin-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Start Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    End Date
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {assignments.map((assignment, i) => (
                  <tr key={i}>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{
                      moment(assignment.start_date).format('MM/DD/YY')
                    }   </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{moment(assignment.end_date).format('MM/DD/YY')}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {(() => {
                        switch (type) {
                          case 'current':
                            return `Ends in ${moment(assignment.end_date).diff(moment(), 'days')} days`;
                          case 'upcoming':
                            return `Starts in ${moment(assignment.start_date).diff(moment(), 'days')} days`;
                          case "past":
                            return '6 issues resolved'
                          default:
                            return 'n/a';
                        }
                      })()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  )
}


function NoAssignmentUI() {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Assignments</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Assignment details</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <p className="mt-2  text-sm leading-6 text-red-500">No assignments found.</p>
      </div>
    </>
  )
}
