import React from 'react';

export default function Loading() {
  return (
    <div className="flex justify-center items-center h-full">
      <img src="garbage-can-flame.gif" alt="Loading..." className="w-12 h-24" />
    </div>
  );
};

