import { URL } from ".";
import { toast } from "react-hot-toast";

const getStatus = async () => {
  try {
    const response = await fetch(`https://healthcheck.bellburd.com/dev/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      }
    });
    return await response.json();
  } catch (error) {
    return { message: 'error retrieving status' }
  }
}

export {
  getStatus
}