import { useEffect, useState } from "react"
import { useUser } from "../contexts/user";
import { resetPassword } from "../api/authentication";

export default function SettingsPage() {
  const user = useUser();

  // password field
  const [form, setForm] = useState({
    password: ""
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { token, id } = user;

    await resetPassword({
      new_password: form.password,
      token,
      user_id: id,
    })

    setForm({
      password: ""
    })
  }

  useEffect(() => { }, [setForm])

  return (
    <div>
      <div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 pb-5 mb-5">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Settings</h3>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Update your password</h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>Change the password you want to use to access your account.</p>
            </div>
            <form onSubmit={handleSubmit} className="mt-5 sm:flex sm:items-center">
              <div className="w-full sm:max-w-xs">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  placeholder="password"
                />
              </div>
              <button
                type="submit"
                className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
