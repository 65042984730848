import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useUser } from "../contexts/user";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { getOrgAssignments, deleteAssignment } from "../api/assignments";
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

export default function AssignmentsList(args) {
  const user = useUser();
  const user_is_admin = user.organization_role != 'member';
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [currentAssignments, setCurrentAssignments] = useState([])
  const [pastAssignments, setPastAssignments] = useState([])
  const [futureAssignments, setFutureAssignments] = useState([])
  const [assignments, setAssignments] = useState([])

  const fetchAssignments = async () => {
    setLoading(true)
    const response = await getOrgAssignments({ token: user.token, user_id: user.id, org_id: user.organization })
    if (!response) return;
    setAssignments([...response.data].sort((a, b) => moment(a.start_date).isAfter(moment(b.start_date))))
    setPastAssignments([...response.data].filter(assignment => moment(assignment.end_date).isBefore(moment())))
    setCurrentAssignments([...response.data].filter(assignment => moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())))
    setFutureAssignments([...response.data].filter(assignment => moment(assignment.start_date).isAfter(moment())))
    setLoading(false)
  }

  const deleteAssignmentClicked = async (assignment_id) => {
    const response = await deleteAssignment({ token: user.token, user_id: user.id, assignment_id })
    if (!response) return;
    fetchAssignments()
  }

  const isCurrentAssignment = (assignment) => {
    return moment(assignment.start_date).isBefore(moment()) && moment(assignment.end_date).isAfter(moment())
  }
  useEffect(() => {
    fetchAssignments()
  }, [setAssignments])

  return (
    <>
      <div className="px-4 pt-8 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {user_is_admin && <button
              type="button"
              onClick={() => navigate(`/org/${user.organization}/assignments/new`)}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 sm:w-auto"
            >
              Create Assignment
            </button>}
          </div>
        </div>
        <AssignmentTable
          assignment_type={'Current Assignment'}
          assignments={currentAssignments}
          user_is_admin={user_is_admin}
          isCurrentAssignment={isCurrentAssignment}
          deleteAssignmentClicked={deleteAssignmentClicked}
        />
        <AssignmentTable
          assignment_type={'Future Assignments'}
          assignments={futureAssignments}
          user_is_admin={user_is_admin}
          isCurrentAssignment={isCurrentAssignment}
          deleteAssignmentClicked={deleteAssignmentClicked}
        />
        <AssignmentTable
          assignment_type={'Past Assignments'}
          assignments={pastAssignments}
          user_is_admin={user_is_admin}
          isCurrentAssignment={isCurrentAssignment}
          deleteAssignmentClicked={deleteAssignmentClicked}
        />
      </div>
    </>
  )
}

function AssignmentTable({ assignment_type, assignments, user_is_admin, isCurrentAssignment, deleteAssignmentClicked }) {
  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <h1>{assignment_type}</h1>
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Start
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      End
                    </th>
                    {user_is_admin && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Delete
                    </th>}
                    {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Edit</span>
                  </th> */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {assignments?.map((assignment, i) => (
                    <tr key={i} className={`${isCurrentAssignment(assignment) && 'bg-blue-200'}`}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 ">
                        <Link className="text-emerald-600 hover:text-emerald-900" to={`/profile/${assignment.user._id}`}>
                          {assignment?.user?.name}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {moment(assignment?.start_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                        {/* <span className="text-red-500">{bank?.auth_refresh_required ? ' - Authentication Refresh Required' : ''}</span> */}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(assignment?.end_date).tz(TIMEZONE).format('MM/DD/YYYY')}
                      </td>
                      {user_is_admin && <td
                        className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-red-500 hover:text-red-900"
                        onClick={() => deleteAssignmentClicked(assignment._id)}
                      >
                        Delete
                      </td>}
                      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{assigneejlll?.authRefresh ? 'true' : 'false'}</td> */}
                      {/* <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <p onClick={() => deleteBankClicked(account?._id)} className="text-emerald-600 hover:text-emerald-900">
                        Delete
                      </p>
                    </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}