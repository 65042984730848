import React, { useState } from "react"
import { useUser, useUserDispatch } from "../contexts/user";
import { createTeam } from '../api/organizations'
import { useNavigate } from 'react-router-dom';

export default function TeamForm({ setShowForm }) {
  const user = useUser();
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const [updating] = useState(false)

  const [teamData, setTeamData] = useState({
    name: null,
  });

  const handleChange = (e) => {
    setTeamData({ ...teamData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name } = teamData;
    const { id, token, organization } = user;

    if (updating) {
      handleUpdateNotification({ notification_id: _id, name, recipients, accounts, user_id: id, token, dispatch })
    } else {
      const result = await createTeam({ name, user_id: id, token, org_id: organization })
      console.log('test', { result })
    }
    navigate(-1)
  }

  return (
    <>

      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8">
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">New Team</h3>
              </div>

              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


                <div className="sm:col-span-3">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      onChange={handleChange}
                      type="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
              >
                {updating ? 'Update' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
