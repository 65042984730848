import React, { useEffect, useState } from "react";
import { useUser, useUserDispatch } from '../contexts/user';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getUser, updateUser } from "../api/users";

export default function ProfilePage() {
  const user = useUser()
  const navigate = useNavigate()
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userViewingOwnProfile, setUserViewingOwnProfile] = useState(false)
  const [userCanEditProfile, setUserCanEditProfile] = useState(false);
  const { user_id } = useParams()
  const user_is_admin = user.organization_role != 'member';
  const [updatedProfile, setUpdatedProfile] = useState(null)
  const [editProfile, setEditProfile] = useState(false)


  // if user being viewed is the current user render edit button
  // confirm with token in the backend
  // make call to update profile
  // return updated profile
  // rerender after edit

  const editProfileClicked = () => {
    setUpdatedProfile({ ...profile })
    setEditProfile(true)
  }

  const fetchProfile = async () => {
    setLoading(true)
    // const requested_user_is_current_user = user.id === user_id;
    // if (requested_user_is_current_user) {
    //   setProfile({ ...user })
    //   console.log('user: ', user)
    //   setLoading(false)
    //   return;
    // }

    const response = await getUser({ token: user.token, user_id })
    setUserCanEditProfile(user_is_admin || response.data._id === user_id)

    if (!response) return;
    setProfile({ ...response.data })
    setLoading(false)
  }

  const handleSubmit = async (formData) => {
    const { cell, name, email } = formData;
    const { token } = user;

    const response = await updateUser({ cell, name, email, user_id, token })
    setProfile({ ...response.data })
    setEditProfile(false)
  }


  useEffect(() => {
    fetchProfile()
  }, [setProfile])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          {editProfile &&
            <EditProfileUI
              profile={profile}
              cancel={() => setEditProfile(false)}
              onSubmit={handleSubmit}
            />
          }
          {!editProfile && (loading
            ? <>Loading...</>
            : <div>
              {profile ? <ProfileUI profile={profile} userCanEditProfile={userCanEditProfile} editProfileClicked={editProfileClicked} /> : <NoProfileUI />}
            </div>
          )}
        </div>
      </div>
    </>

  )
}

function EditProfileUI({ profile, onSubmit, cancel }) {
  // Assume useState is imported from React to manage form state
  const [formData, setFormData] = useState({
    name: profile.name,
    title: profile.title,
    email: profile.email,
    cell: profile.cell
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formData); // Call the onSubmit handler with the updated data
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Edit Profile</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Update your contact details and role information.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <label className="text-sm font-medium leading-6 text-gray-900" htmlFor="name">Full name</label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full sm:col-span-2 p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <label className="text-sm font-medium leading-6 text-gray-900" htmlFor="email">Email address</label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full sm:col-span-2 p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <label className="text-sm font-medium leading-6 text-gray-900" htmlFor="cell">Cell</label>
          <input
            id="cell"
            name="cell"
            type="text"
            value={formData.cell}
            onChange={handleChange}
            className="mt-1 block w-full sm:col-span-2 p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      {(
        <div className="flex justify-end mt-6 pt-4 border-t border-gray-100">
          <button type="button" className="px-4 py-2 text-white bg-gray-500 hover:bg-gray-700 rounded-lg" onClick={cancel}>
            Cancel
          </button>
          <button type="submit" className="px-4 ml-4 py-2 text-white bg-emerald-500 hover:bg-emerald-700 rounded-lg">
            Save
          </button>
        </div>
      )}
    </form>
  );
}


function ProfileUI({ profile, userCanEditProfile, editProfileClicked }) {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Profile </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Contact details and role information.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.name} </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Title</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.title}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Cell</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{profile.cell}</dd>
          </div>
        </dl>
      </div>
      {!!userCanEditProfile && (
        <div className="flex justify-end mt-6 pt-4 border-t border-gray-100">
          <button className="px-4 py-2 text-white bg-emerald-500 hover:bg-emerald-700 rounded-lg"
            onClick={editProfileClicked}
          >
            Edit Profile
          </button>
        </div>
      )}
    </>

  )
}

function NoProfileUI() {
  return (
    <>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Profile </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Contact details and role information.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <p className="mt-2  text-sm leading-6 text-red-500">No profile found.</p>
      </div>
    </>
  )
}
