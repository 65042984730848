import { URL } from ".";
const API_KEY = process.env.REACT_APP_AWS_API_KEY;
import toast from 'react-hot-toast';

const signup = async ({ email, password }) => {
  try {
    const response = await fetch(`${URL}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password
      })
    });
    return await response.json();
  } catch (error) {
    alert(error);
  }
}
const resetPasswordAnonymous = async ({ email }) => {
  try {
    const response = await fetch(`${URL}/auth/password/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email
      })
    });
    return await response.json();
  } catch (error) {
    alert(error);
  }
}

const signin = async ({ password, email }) => {

  try {
    const response = await fetch(`${URL}/auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password
      })
    });

    if (response.status !== 200) {
      throw new Error('Error signing in.')
    }

    return await response.json();
  } catch (error) {
    toast.error(error.message);
    return null;
  }
}

const resetPassword = async ({ new_password, token, user_id }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/resetPassword`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`

      },
      body: JSON.stringify({
        new_password
      })
    });

    if (!response.ok) throw new Error('Error resetting password.')

    toast.success('Password reset successfully.')
    return await response.json();
  } catch (error) {
    toast.error(error.message);
    return null
  }
}

export { signin, signup, resetPassword, resetPasswordAnonymous }