import { URL } from ".";
import { toast } from "react-hot-toast";

const getTeam = async ({ team_id, token }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving team' }
  }
}
const getTeamIssues = async ({ team_id, token, page_number, count }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/issues?page_number=${page_number}&count=${count}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving team' }
  }
}
const getRecentTeamIssues = async ({ team_id, token }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/issues?recent=${true}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    toast.error('Error retrieving issues')
    return { data: {}, message: 'error retrieving team' }
  }
}
const addMembersToTeam = async ({ members, user_id, org_id, team_id, token }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/teams/${team_id}/members/new`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        members
      })
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error adding members' }
  }
}

const getTeamAssignments = async ({ team_id, token, order }) => {
  try {
    const response = await fetch(`${URL}/teams/${team_id}/assignments${!!order ? `?order=${order}` : ''}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return await response.json();
  } catch (error) {
    alert(error);
    return { message: 'error retrieving team' }
  }
}

const createTeam = async ({ name, user_id, token, org_id }) => {
  try {
    const response = await fetch(`${URL}/user/${user_id}/org/${org_id}/team`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        name,
      })
    });
    if (!response.ok) throw new Error('error creating team');

    toast.success('Team created successfully')
    return await response.json();
  } catch (error) {
    toast.error('Error createing team')
    return null
  }
}

export { getTeam, createTeam, getTeamAssignments, addMembersToTeam, getTeamIssues, getRecentTeamIssues }

